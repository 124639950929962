import { cloneElement, ForwardedRef, forwardRef, HTMLAttributes, JSX, useRef } from 'preact/compat';
import HashCode from 'hash/HashCode.ts';
import MaybeSignal, { isReadOnlySignal, isSignal, Signalify, Unsignalify } from 'api/types/MaybeSignal.ts';
import Booleanify from 'api/types/Booleanify.ts';
import { TailwindWidth, WidthClass } from 'api/style/Width.ts';
import { HeightClass, TailwindHeight } from 'api/style/Height.ts';
import SignalLike from 'api/types/SignalLike.ts';
import IconCircleFilled from 'tabler-icons/circle-filled.tsx';
import { ComponentChildren, VNode } from 'preact';
import { computed, useComputed, useSignal } from '@preact/signals';
import Colorify, { ColorHexify } from 'api/style/Colorify.ts';

export interface ButtonColorProps {
    text?: string;
    bg?: string;
    hover?: string | { bg?: string; text?: string };
}

export type ButtonProps =
    & Omit<
        Omit<
            Omit<
                Omit<
                    HTMLAttributes<HTMLButtonElement>,
                    'title'
                >,
                'icon'
            >,
            'disabled'
        >,
        'ping'
    >
    & Omit<JSX.DOMAttributes<HTMLButtonElement>, 'class'>
    & {
        width?: TailwindWidth | number | string;
        height?: TailwindHeight | number | string;
        disabled?: MaybeSignal<boolean | 'true' | 'false'>;
        color?: MaybeSignal<ButtonColorProps>;
        shadow?: boolean | 'true' | 'false';
        border?:
            | boolean
            | 'true'
            | 'false'
            | number
            | 'none'
            | 'hidden'
            | 'solid'
            | 'dashed'
            | 'dotted'
            | 'double'
            | {
                width?: number;
                type?:
                    | 'none'
                    | 'hidden'
                    | 'solid'
                    | 'dashed'
                    | 'dotted'
                    | 'double';
                color?: string;
            };
        ping?: MaybeSignal<boolean | 'true' | 'false'> | {
            icon?: JSX.Element;
            status?: MaybeSignal<boolean | 'true' | 'false'>;
            color?: string;
        };
    }
    & ({
        /** @deprecated */ round?: never;
        rounded?:
            | boolean
            | 'true'
            | 'false'
            | 'none'
            | 'sm'
            | 'md'
            | 'lg'
            | 'xl'
            | '2xl'
            | '3xl'
            | 'full';
    } | {
        /** @deprecated */ round?:
            | boolean
            | 'true'
            | 'false'
            | 'none'
            | 'sm'
            | 'md'
            | 'lg'
            | 'xl'
            | '2xl'
            | '3xl'
            | 'full';
        rounded?: never;
    })
    & ({ title: MaybeSignal<ComponentChildren>; icon?: never } | {
        icon: MaybeSignal<string | JSX.Element | undefined>;
        title?: never;
    })
    & (
        | { static?: boolean | 'true' | 'false'; bounce?: never; pulse?: never }
        | {
            static?: never;
            bounce?: boolean | 'true' | 'false';
            pulse?: boolean | 'true' | 'false';
        }
    );

const Button = forwardRef(function Button(
    props: ButtonProps,
    ref?: ForwardedRef<HTMLDivElement>,
) {
    props.type ??= 'button';

    props.id ??= 'Button_' + HashCode.bind(props)().toString() + '_' +
        Math.round(Math.random() * (10 ** 12)).toString();

    if (props.title && !isSignal(props.title)) {
        props.title = useSignal(props.title);
    }

    props.shadow ??= false;

    props.rounded ??= props.round ?? true;

    if (props.rounded.toString() == 'false') {
        props.rounded = 'none';
    } else if (
        typeof props.rounded == 'undefined' ||
        props.rounded.toString() == 'true'
    ) {
        props.rounded = 'md';
    }

    if (
        props.border === true ||
        (typeof props.border == 'string' && props.border != 'false')
    ) {
        props.border = 'solid';
    }

    if (!Booleanify(props.border)) {
        props.border = 'none';
    }

    if (Booleanify(props.static)) {
        props.bounce = props.pulse = false;
    } else {
        props.bounce = typeof props.bounce == 'undefined' ||
            Booleanify(props.bounce);
        props.pulse = typeof props.pulse == 'undefined' ||
            Booleanify(props.pulse);
    }

    if (!(typeof props.ping == 'object' && 'status' in props.ping)) {
        props.ping = {
            status: props.ping as MaybeSignal<boolean | 'true' | 'false'>,
            icon: undefined,
        } as typeof props.ping; // weird type bug
    }

    if (
        isSignal(
            (props.ping as { status: MaybeSignal<boolean | 'true' | 'false'> })!
                .status,
        )
    ) {
        if (
            !isReadOnlySignal(
                (props.ping as {
                    status: MaybeSignal<boolean | 'true' | 'false'>;
                })!
                    .status,
            )
        ) {
            (props.ping as { status: SignalLike<boolean | 'true' | 'false'> })!
                .status.value = Booleanify(
                    (props.ping as {
                        status: SignalLike<boolean | 'true' | 'false'>;
                    })!.status,
                );
        }
    } else {
        (props.ping as { status: SignalLike<boolean | 'true' | 'false'> })!
            .status = Signalify<boolean>(
                Booleanify(
                    (props.ping as {
                        status: SignalLike<boolean | 'true' | 'false'>;
                    })!
                        .status,
                ),
            ) as SignalLike;
    }

    if (!(props.ping as { icon?: JSX.Element }).icon) {
        (props.ping as { icon?: JSX.Element }).icon = <IconCircleFilled />;
    }

    if (!(props.ping as { color?: string }).color) {
        (props.ping as { color?: string }).color = 'dspace';
    }

    if (isSignal(props.disabled)) {
        if (!isReadOnlySignal(props.disabled)) {
            props.disabled.value = Booleanify(props.disabled);
        }
    } else {
        props.disabled = Signalify<boolean>(Booleanify(props.disabled));
    }

    if (!isSignal(props.alt)) {
        props.alt = Signalify<string>(
            props.alt?.toString() ?? (typeof props.title == 'string' ? props.title : undefined) ?? '',
        ) as SignalLike;
    }

    const c = computed(() => ({
        ...(Unsignalify(props.color) ?? {}),
        bg: Colorify(Unsignalify(props.color)?.bg, 'dspace'),
        text: Colorify(Unsignalify(props.color)?.text, 'white'),
        hover: {
            bg: Colorify(
                typeof Unsignalify(props.color)?.hover == 'object'
                    ? (Unsignalify(props.color)!.hover! as { bg?: string }).bg
                    : (Unsignalify(props.color)?.hover as string),
                ColorHexify(Unsignalify(props.color)?.bg, 'dspace'),
            ),
            text: Colorify(
                typeof Unsignalify(props.color)?.hover == 'object'
                    ? (Unsignalify(props.color)!.hover! as { text?: string }).text
                    : undefined,
                ColorHexify(Unsignalify(props.color)?.text, 'inherit'),
            ),
        },
    }));

    ref ??= useRef<HTMLDivElement>(null);
    const bref = useRef<HTMLButtonElement>(null);

    const onClick = (() => {
        switch (props.type) {
            case 'submit':
                return undefined;

            case 'reset':
                return (e: Event) => {
                    e.stopImmediatePropagation();
                    e.preventDefault();

                    bref.current?.form?.reset();
                };

            default:
                return (e: Parameters<NonNullable<typeof props['onClick']>>[0]) => {
                    e.stopImmediatePropagation();
                    e.preventDefault();

                    props.onClick?.(e);
                };
        }
    })();

    const disabled = useComputed(() => Booleanify(props.disabled));

    return (
        <div
            ref={ref}
            onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                    e.preventDefault();
                    e.currentTarget.click();
                }
            }}
            onClick={props.type == 'submit' ? undefined : (e) => bref.current?.dispatchEvent(e)}
            disabled={disabled}
            className={`group relative bg-transparent ${WidthClass(props.width ?? 'fit')} ${
                HeightClass(props.height ?? 'fit')
            } select-none flex ${
                Booleanify(props.shadow) ? 'shadow-sm' : ''
            } ${(props.rounded && props.rounded != 'none'
                ? `rounded-${props.rounded}`
                : 'rounded-none')} items-center justify-items-center justify-center ${
                Booleanify(props.disabled)
                    ? 'cursor-not-allowed'
                    : `cursor-pointer [&>*]:hover:bg-${Unsignalify(c)!.hover!.bg} hover:text-${
                        Unsignalify(c)!.hover!.text
                    } hover:text-${Unsignalify(c)!.hover!.text} [&>*]:hover:text-${Unsignalify(c)!.hover!.text}`
            } ${(((typeof props.className == 'string' ? props.className : props.className?.value) ?? '').split(' ')
                .reduce(
                    (str, x) => str + ` !${x}`,
                    '',
                ))} ${!Booleanify(props.disabled) && props.pulse ? 'hover:animate-pulse' : ''} ${
                !Booleanify(props.disabled) && props.bounce ? 'active:animate-bounce' : ''
            }`}
        >
            <div
                className={useComputed(() =>
                    `group relative bg-transparent ${
                        Booleanify(props.disabled) ? '' : `[&>*]:hover:bg-${
                            Unsignalify(c)!
                                .hover!.bg
                        } group-hover:bg-${Unsignalify(c)!.hover!.bg} [&>*]:hover:text-${
                            Unsignalify(c)!
                                .hover!.text
                        } group-hover:text-${Unsignalify(c)!.hover!.text} hover:text-${Unsignalify(c)!.hover!.text}`
                    } flex items-center justify-items-center`
                )}
            >
                {Booleanify(
                    (props.ping as {
                        status: SignalLike<boolean | 'true' | 'false'>;
                    })!.status,
                ) &&
                    cloneElement(
                        (props.ping as { icon?: JSX.Element }).icon as VNode,
                        {
                            className: `left-0 top-0 z-50 w-3 h-3 absolute animate-ping ${
                                (props.ping as { color?: string }).color!
                                        .startsWith('#') ||
                                    (props.ping as { color?: string })
                                        .color!
                                        .startsWith('rgb(')
                                    ? `text-[${(props.ping as {
                                        color?: string;
                                    })
                                        .color!}]`
                                    : `text-${(props.ping as {
                                        color?: string;
                                    })
                                        .color!}`
                            }`,
                        },
                    )}

                <button
                    {...{
                        ...props as unknown as ButtonProps & { icon?: string },
                        width: undefined,
                        icon: undefined,
                        height: undefined,
                    }}
                    disabled={disabled}
                    onClick={onClick}
                    onAuxClick={onClick}
                    ref={bref}
                    title={useComputed(() =>
                        (Unsignalify(props.icon) == 'undefined'
                            ? props.alt
                            : typeof Unsignalify(props.title) == 'string'
                            ? Unsignalify(props.title)?.toString()
                            : Unsignalify(props.alt)) ?? undefined
                    )}
                    className={useComputed(() =>
                        `${(props
                                .rounded &&
                                props.rounded != 'none'
                            ? `rounded-${props.rounded} ${
                                props.border != 'none' &&
                                    (typeof props.border != 'number' ||
                                        props.border > 0) &&
                                    (typeof props.border != 'object' ||
                                        props.border.type != 'none') &&
                                    (typeof props.border != 'object' ||
                                        props.border.width! > 0)
                                    ? ''
                                    : 'px-3 py-3'
                            }`
                            : `rounded-none ${
                                props.border != 'none' &&
                                    (typeof props.border != 'number' ||
                                        props.border > 0) &&
                                    (typeof props.border != 'object' ||
                                        props.border.type != 'none') &&
                                    (typeof props.border != 'object' ||
                                        props.border.width! > 0)
                                    ? ''
                                    : 'px-3 py-2'
                            }`)} ${
                            typeof props.border == 'object' && props.border.color
                                ? `border-${
                                    props.border.color.startsWith('#') ? `[${props.border.color}]` : props.border.color
                                }`
                                : ''
                        } group relative text-sm font-semibold focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed text-${Unsignalify(
                            c,
                        )!.text!} bg-${Unsignalify(c)!.bg!} ${
                            disabled.value
                                ? ''
                                : `hover:bg-${Unsignalify(c)!.hover!.bg} hover:text-${Unsignalify(c)!.hover!.text}`
                        }`
                    )}
                >
                    {typeof props.icon == 'undefined'
                        ? props.title
                        : typeof Unsignalify(props.icon) == 'string'
                        ? <img src={props.icon} />
                        : cloneElement(Unsignalify(props.icon), {
                            disabled,
                            className: `flex items-center justify-center justify-items-center m-auto ${(Unsignalify(
                                props.icon?.props
                                    ?.className ??
                                    props.icon?.props?.class ?? '',
                            ))} text-${Unsignalify(c)!.text!} hover:text-${
                                Unsignalify(c)!.hover!.text
                            } group-hover:text-${Unsignalify(c)!.hover!.text}`,
                        })}
                </button>
            </div>
        </div>
    );
});

export default Button;
